<template>
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Acompanhamento da Implantação
      </div>
    </div>
    <div class="page-title">
      Acompanhamento da Implantação
    </div>
    <div class="attendances-page__clear-filter-container">
      <Button class="action-buttons" :iconName="'description'" title="Excel" :text="'Excel'" :action="downloadExcel" style="margin-right: 1vw;"/>
      <Button class="action-buttons" :iconName="'cancel'" title="Limpar Filtros" :text="'Limpar Filtros'" :action="clearFilters"/>
    </div>
    <div class="attendances-page__actions-container">
      <StandardInput :placeholder="'Grupo ou razão social'" 
                     :title="'Grupo ou Razão Social'" 
                     :action="setGroupValue" 
                     :value="filters.name"
                     class="attendances-page__actions-inputs"
                     :type="'text'"/>
      <StandardInput :placeholder="'Selecione o atendente'" 
                     :action="setAttendantValue" 
                     :value="filters.attendant"
                     :title="'Atendente'" 
                     :list="arraySelectAttendant" 
                     class="attendances-page__actions-inputs"
                     :type="isResaleAdminLoggedIn ? 'select' : 'disabled'"/>
      <StandardInput v-if="isResaleAdminLoggedIn"
                     :placeholder="'Selecione a revenda'" 
                     :action="setResaleValue" 
                     :value="filters.resale"
                     :title="'Revenda'" 
                     class="attendances-page__actions-inputs"
                     :list="arraySelectResale" 
                     :type="'select'"/>
      <StandardInput v-if="!isResaleAdminLoggedIn"
                     :placeholder="'Selecione a revenda'" 
                     :action="setResaleValue" 
                     :value="filters.resale"
                     :title="'Revenda'" 
                     class="attendances-page__actions-inputs"
                     :list="arraySelectResale" 
                     :type="'disabled'"/>
      <StandardInput :placeholder="'Selecione o estado'" 
                     :action="setStateValue"
                     :title="'Estado'" 
                     :list="arraySelectState" 
                     class="clients-page__actions-inputs"
                     :type="'select'"/>
      <StandardInput :placeholder="'Selecione o segmento'" 
                     :action="setSegmentValue"
                     :title="'Segmento'" 
                     :list="arraySelectSegment" 
                     class="clients-page__actions-inputs"
                     :type="'select'"/>
    </div>
    <div class="attendances-page__actions-container">
      
      <div class="attendances-page__actions-wrapper">
        <div class="attendances-page__input-title-wrapper">
          <span class="attendances-page__input-title">Status</span>
        </div>
        <cc-select-v2 class="status-filter-select select-input-width status-select"
                      id="attendant"
                      :tracker="'id'"
                      :text="'name'"
                      placeholder="Status"
                      :searchable="true"
                      :empty="true"
                      :multiple="true"
                      v-model="filters.status"
                      :options="statuses" />
      </div>
    </div>
    
    <div class="page-selected-filters-container">
	    <div v-if="filters.name" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.name }}</div>
        <span @click="setGroupValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.attendant" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.attendant.name }}</div>
        <span @click="setAttendantValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.resale" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.resale.name }}</div>
        <span @click="setResaleValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
    </div>
    <div class="attendances-page__infos-wrapper">
      <div v-if="getStartedStatus" class="attendances-page__started-request mobile-alert-size">Iniciado: {{ startedStatusRequests }}</div>
      <div v-if="getWaitingRequestStatus" class="attendances-page__waiting-first-request mobile-alert-size">Aguardando Cotação: {{ waiting_requestStatusRequests }}</div>
      <div v-if="getOnWatchStatus" class="attendances-page__on-watch-request mobile-alert-size">Em acompanhamento: {{ on_watchStatusRequests }}</div>
      <div v-if="getOnAlertStatus" class="attendances-page__on-alert-request mobile-alert-size">Em alerta: {{ on_alertStatusRequests }}</div>
      <div v-if="getImplantedStatus" class="attendances-page__implanted-request mobile-alert-size">Implantado: {{ implantedStatusRequests }}</div>
      <div v-if="getSuspendedStatus" class="attendances-page__suspended-request mobile-alert-size">Suspenso: {{ suspendedStatusRequests }}</div>
      <!-- <div class="attendances-page__total-request">Total de Requisições: 00</div> -->
    </div>
    <cc-loader v-show="is_loading" class="default" />
    <no-data-available v-if="total_items == 0 && !is_loading" :text="'Nenhum produto encontrado'" />
    <div v-show="!is_loading" class="attendance-page-table-container">
      <div v-show="total_items > 0" class="page-table-header">
        <div class="page-table-header-text actions-width">Ações</div>
        <div class="page-table-header-text id-width">ID / DT Cadastro</div>
        <div class="page-table-header-text group-width">CNPJ / Telefone</div>
        <div class="page-table-header-text corporate-width">Razão / Grupo</div>
        <div class="page-table-header-text area-width">Segmento</div>
        <div class="page-table-header-text resale-width">Revenda / Estado</div>
        <div class="page-table-header-text responsible-width">Responsável</div>
        <div class="page-table-header-text status-width">Status</div>
      </div>
      <div v-for="(client, idx) in clients" 
            :key="client.id" 
            :class="{
                    canceled: client.attendance.status == 'CANCELED',
                    waiting: client.attendance.status == 'WAITING_CONFIRMATION',
                    confirmed: client.attendance.status == 'CONFIRMED',
                  }">
        <!-- desktop -->
        <div :class="getSpecialBackground(idx)" class="page-table-row desktop">
          <div class="page-table-desc-column actions-width">
            <div class="page-table-header-mobile">Ações</div>
            <div>
              <span @click="showMoreModal=client" class="material-icons-outlined answers-icon">settings</span>
              <router-link style="font-size: inherit;" 
                           :to="{ 
                              name: 'monitors-page', 
                              params: { tab: 'cotacoes' }, 
                              query: { client_id: client.id } 
                            }"
                           :target="'_blank'">
                <span class="material-icons-outlined monitor-icon">
                  desktop_windows
                </span>
              </router-link>
              <span @click="openEditAttachedFilesModal(client)"
                    title="Ver arquivos anexados" 
                    class="material-icons-outlined attach-file-icon">
                attach_file
              </span>
              <span @click="edit(client)"
                    v-if="can('CLIENT', 'UPDATE')"
                    title="Editar" 
                    class="material-icons-outlined edit-user-icon">
                edit
              </span>
            </div>
          </div>

          <div class="page-table-desc-column id-width"><div class="page-table-header-mobile">ID</div>#{{ client.id }} <br> <span style="font-weight: 600;">{{ client.created_at | formatDateOnly }}</span></div>
          <div class="page-table-desc-column group-width">
            <div class="page-table-header-mobile">CNPJ / Telefone</div>
            <div>
              <strong style="font-weight: 600;">{{ client.cnpj || "" | VMask('## ### ###/####-##') }}</strong>
              <br/>
              <cc-whatsapp style="padding: 0 !important;" :phone="client.phone" />
            </div>
            
          </div>
          <div class="page-table-desc-column corporate-width">
            <div class="page-table-header-mobile">Razão / Grupo</div>
            <div>
              <strong style="font-weight: 600;">{{ client.head_office ? client.head_office.social_reazon : '' }}</strong>
              <br/>
              {{ client.group_name }}
            </div>
          </div>
          <div class="page-table-desc-column area-width">
            {{ client.sectors.length>0 && client.sectors[0].name }}
          </div>
          <div class="page-table-desc-column resale-width"><div class="page-table-header-mobile">Revenda</div>{{ client.resale ? client.resale.name : '' }} <br> <div class="page-table-header-mobile" >Estado</div> <span style="font-weight: 600;">{{ client.head_office ? client.head_office.state.fu : '' }}</span></div>
          <div class="page-table-desc-column responsible-width">
            <div class="page-table-header-mobile">Responsável</div>
            <div class="change-status">
              <select class="page-table-input-select" v-model="client.attendance.attendant" v-on:change="updateAttendance(client)">
                <option :value="client.attendance.attendant" disabled>{{  client.attendance.attendant.name }}</option>
                <option v-for="manager in arraySelectAttendant" :key="manager.value.id" :value="manager.value">{{manager.text}}</option>
              </select>
              <i class="fa fa-check success-update" :class="{ visible: client.saved }" />
            </div>
          </div>
          <div class="page-table-desc-column status-width">
            <div class="page-table-header-mobile">Status</div>
            <div class="manage-status">
              <!-- <span class="material-icons-outlined edit-table-icon"
                    @click="() => { edit_status(client.attendance) }"
                    :class="{ disabled: !client.is_editing }" 
                    v-if="can('RESALE_ID', 'READ') && !client.attendance.is_editing">
                edit
              </span>
              <span class="material-icons-outlined edit-table-icon"
                    style="color: var(--primary-color);"
                    @click="() => { edit_status(client.attendance) }"
                    :class="{ disabled: !client.is_editing }" 
                    v-if="can('RESALE_ID', 'READ') && client.attendance.is_editing">
                save
              </span> -->
              <div class="change-status">
                <select class="page-table-input-select" v-model="client.attendance.status" v-on:change="updateAttendance(client)">
                  <option v-for="status in statuses" :key="status.id" :value="status">{{status.name}}</option>
                </select>
                <AttendanceStatusCircle style="margin-left: 10px;" :status="client.attendance.status.id" />
              </div>
              <!-- <AttendanceStatus :status="client.attendance.status" /> -->
            </div>
          </div>
        </div>
        <!-- mobile -->
        <MobileClientRow :class="getSpecialBackground(idx)" :client="client" :edit="edit" :open_invitations="open_invitations" :openEditAttachedFilesModal="openEditAttachedFilesModal" :action1="client => {current_client = client; show_comments = true; showCommentModal = true}" :statuses="statuses" :arraySelectAttendant="arraySelectAttendant" :updateAttendance="updateAttendance" />

      </div>
      <div class="box-footer clearfix not-print">
        <Paginacao :key="'pag'"
                    classes="orange"
                    :items_by_page_value="items_by_page_value"
                    :total="total_items"
                    :page="filters.page"
                    :page_limit="11"
                    v-on:change="filters.page = $event">
        </Paginacao>
      </div>
      <div class="total-requests-container">Total de Requisições: {{ totalRequests }} registros</div>
    </div>
    <MoreActionsModal v-if="showMoreModal" :client="showMoreModal" :closeDialog="()=>showMoreModal=false" :updatePage="list"/>
    <EditAttachedFilesModal v-if="showEditAttachedFilesModal"
                            @close="closeEditAttachedFilesModal"
                            :clientInfo="clientInfo"/>
    <cc-new-client-modal :id="current_client ? current_client.id : null"
                            :closeDialog="closeEditClientModal"
                            v-if="showEditClientModal" />
  </div>
</template>
<script>
import Paginacao from "@/components/cliente/base-components/Pagination";
import AttendanceStatus from "@/components/shared/AttendanceStatus";
import CommentList from "@/components/lists/comments/comment-list.component";
import MoreActionsModal from "@/components/lists/MoreActionsModal";
import StorageService from '@/services/TokenService';
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import UserService from "@/services/v1/user.service";
import UserService3 from "@/services/v3/management/user.service";
import ClientService from "@/services/v3/client.service";
import ResaleService from "@/services/v3//resales/resale.service";
import InvitationsModal from "@/modals/client/invitations/invitations.modal";
import { perm_mixin } from '@/mixins/permission.mixin'
import Button from '@/components/ui/buttons/StandardButton.vue'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import EditAttachedFilesModal from '@/modals/edit-attached-files/EditAttachedFiles.modal';
import NewClientModal from "@/modals/client/new/new-client.modal";
import AttendanceStatusCircle from "@/components/shared/AttendanceStatusCircle";
import ExcelDownload from '@/services/ExcelDownload.js'
import MobileClientRow from "./MobileClientRow.vue";
import * as CONSTANTS from '@/constants/constants';

export default {
    mixins: [ loaderMixin, perm_mixin ],
    components: { /* ccPaginacao:  */
      Paginacao, 
      AttendanceStatus, 
      'cc-new-client-modal' : NewClientModal,
      CommentList, 
      InvitationsModal,
      Button,
      StandardInput,
      EditAttachedFilesModal,
      AttendanceStatusCircle,
      MobileClientRow,
      MoreActionsModal
    },
    data(){
      return{
            showCommentModal: false,
            showInvitationsModal: false,
            showEditAttachedFilesModal: false,
            revenda_id: null,
            arraySelectAttendant: [],
            arraySelectResale: [],
            isResaleAdminLoggedIn: undefined,
            arraySelectState: [],
            arraySelectSegment: [],
            selectedSegment: undefined,
            showMoreModal: false,
            is_loading: false,
            total_items: 1,
            name_filter: "",
            current_client: null,
            show_comments: false,
            asdasd: { id: 'prefinished ', name: 'Pre-Finalizado' },




            statuses: [
                { id: 'STARTED', name: 'Iniciado' },
                { id: 'WAITING_REQUEST', name: 'Aguardando cotação' },
                { id: 'ON_WATCH', name: 'Em acompanhamento' },
                { id: 'IMPLANTED', name: 'Implantado' },
                { id: 'SUSPENDED', name: 'Suspenso' },
                { id: 'ON_ALERT', name: 'Em alerta' },
                { id: 'prefinished ', name: 'Pre-Finalizado' },
                { id: 'suspended_inactivity', name: 'Suspenso por inatividade' },
            ],
            filters: {
                page: 1,
                client: null,
                status: [
                    { id: 'STARTED', name: 'Iniciado' },
                    { id: 'WAITING_REQUEST', name: 'Aguardando cotação' },
                    { id: 'ON_WATCH', name: 'Em acompanhamento' },
                    { id: 'ON_ALERT', name: 'Em alerta' },
                    { id: 'prefinished ', name: 'Pre-Finalizado' },
                ]
            },
            allFilters: {
              page: 1,
              client: null,
              status: [
                { id: 'STARTED', name: 'Iniciado' },
                { id: 'WAITING_REQUEST', name: 'Aguardando cotação' },
                { id: 'ON_WATCH', name: 'Em acompanhamento' },
                { id: 'IMPLANTED', name: 'Implantado' },
                { id: 'SUSPENDED', name: 'Suspenso' },
                { id: 'ON_ALERT', name: 'Em alerta' },
                { id: 'prefinished ', name: 'Pre-Finalizado' },
                { id: 'suspended_inactivity', name: 'Suspenso por inatividade' },
              ]
            },
            resales: [],
            current_client: null,
            show_invitations: false,
            provider_filter: null,
            is_client_modal_open: false,
            showEditClientModal: false,
            filterByProviders: [],
            items_by_page_value: 100,
            user_svc: new UserService(),
            user_svc3: new UserService3(),
            svc: new ClientService(),
            resale_svc: new ResaleService(),
            storage_svc: new StorageService(),
            invitations: [],
            clients: [],
            current_provider: null,
            manage_groups: false,
            resale_id: null,
            breadcrumbs: [
                {
                    name: "Clientes",
                    route: 'clients'
                },
                {
                    name: "Acompanhamento",
                    route: 'clients-attendances'
                }
            ],
            startedStatusRequests: 0,
            on_alertStatusRequests: 0,
            on_watchStatusRequests: 0,
            implantedStatusRequests: 0,
            waiting_requestStatusRequests: 0,
            suspendedStatusRequests: 0,
            totalRequests: 0,
            clientInfo: undefined,
        }
    },
    watch: {
        filters : {
            handler: function() {
              setTimeout(() => {
                this.list(1)
              }, 600);
            },
            deep: true
        },
    },
    computed: {
      getStartedStatus() {
        return this.filters.status.find(s => s.id == 'STARTED')
      },
      getWaitingRequestStatus() {
        return this.filters.status.find(s => s.id == 'WAITING_REQUEST')
      },
      getOnWatchStatus() {
        return this.filters.status.find(s => s.id == 'ON_WATCH')
      },
      getImplantedStatus() {
        return this.filters.status.find(s => s.id == 'IMPLANTED')
      },
      getSuspendedStatus() {
        return this.filters.status.find(s => s.id == 'SUSPENDED')
      },
      getOnAlertStatus() {
        return this.filters.status.find(s => s.id == 'ON_ALERT')
      },
    },
    methods: {
        closeEditClientModal: function() {
          this.showEditClientModal = false;
        },
        edit(client) {
            this.current_client = client
            this.showEditClientModal = true
        },
        downloadExcel(){
          setTimeout(() => {
              const payload = {
                  tableName: 'Acompanhamentos',
                  userName: localStorage.getItem('user_name'),
                  header: ['ID', 'DT Cadastro', 'CNPJ ', 'Telefone', 'Razão', 'Grupo', 'Revenda ', 'Estado', 'Responsável', 'Status'],
                  body: this.clients.map(value => {
                      return [
                          value.id,
                          moment(value.created_at).format("DD/MM/YYYY HH:mm"),
                          value.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1 $2 $3/$4-$5"),
                          value.phone ? value.phone : '--' ,
                          value.head_office ? value.head_office.social_reazon : '--',
                          value.group_name,
                          value.resale ? value.resale.name : '--',
                          value.head_office ? value.head_office.state.fu : '--',
                          value.attendance.attendant.name,
                          value.attendance.status.name
                      ]
                  })
              }
              new ExcelDownload(payload)
          }, 100);
        },
        openEditAttachedFilesModal(client) {
          this.clientInfo = client
          this.showEditAttachedFilesModal = true;
        },
        updateAttendance(client){
          this.update_attendance(client, {
                                    ...client.attendance,
                                    status: { id: client.attendance.status.id }
                                }, client.attendance) 
        },
        getResaleId() {
          this.revenda_id = this.storage_svc.get("revenda");
          if (this.revenda_id == 1) {
            this.isResaleAdminLoggedIn = true
          } else {
            this.isResaleAdminLoggedIn = false
            this.filters.resale = {id: this.revenda_id}
          }
        },
        closeInvitationsModal: function() {
          this.showInvitationsModal = false;
        },
        closeCommentModal: function() {
          this.showCommentModal = false;
        },
        closeEditAttachedFilesModal: function() {
          this.showEditAttachedFilesModal = false;
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        clearFilters() {
          this.filters.name = ''
          this.filters.attendant = ''
          if (this.isResaleAdminLoggedIn) this.filters.resale = ''
          this.filters.status = []
        },
        setGroupValue(value){
		      this.filters.name = value
          this.page = 1
          this.getListTotals(this.page)
          this.list(this.page)
		    },
        setAttendantValue(value){
		      this.filters.attendant = value
          this.page = 1
          this.getListTotals(this.page)
          this.list(this.page)
		    },
        setResaleValue(value){
		      this.filters.resale = value
          this.page = 1
          this.getListTotals(this.page)
          this.list(this.page)
		    },
        setStatusValue(value){
		      this.filters.status = value
		    },
        open_invitations(client) {
          this.current_client = client
          this.showInvitationsModal = true
        },
        edit_status(attendance) {
            attendance.is_editing = !attendance.is_editing
            this.$forceUpdate()
        },
        load_resales() {
			return this.resale_svc.list().then(response => {
                this.resales = response.data.data
                response.data.data.forEach(element => {
                    this.arraySelectResale.push({
                        text: element.name,
                        value: element
                    })
                })
            })
		},
        update_attendance(client, attendance, obj_ref) {
            this.svc.update_attendance(client, {
                id: attendance.id, status: attendance.status.id,
                attendant_id: attendance.attendant.id
            }).then(response => response.data).then((data)=>{
                attendance.saved = true
                obj_ref.is_editing = false
                const notification = {
                    type: 'success',
                    message: 'Dados salvos com sucesso!'
                }
                this.$store.dispatch('notification/add', notification)
                this.$forceUpdate()
            })
        },
        renotify() {
            this.confirm_action({
                subtitle: `#${this.filters.client.name.toUpperCase()}`,
				message: "Confirma reenvio de notificações dos convites pendentes?",
				callback: () => {
                    this.present_loader("Reenviando notificações...")
                    this.svc.notify_all(this.filters.client).then(() => {
                        this.present_info("Notificações Enviadas!")
                    })
				}
			})
        },
      load_region_data() {
			return this.user_svc.region_data().then(response => response.data).then(data => {
				this.arraySelectState = data.estados.map(element => {
          return {
                text: element.est_nome,
                value: element
              }
        })
			})
		},
    getListTotals() {
      let params = {
        page: this.filters.page,
        status: this.filters.status ? this.filters.status.map(s => s.id) : [],
        name: this.filters.name,
        attendant_id: this.filters.attendant ? this.filters.attendant.id : null,
        resale: this.filters.resale ? this.filters.resale.id: null, 
        sort: 'tbl_cliente.cli_id',
        count: 1
      }
        this.svc.list(params)
        .then(response => response.data).then(data => {

          const resp = {}
          data.data.forEach((item) => {
            if(item.status == 'ON_ALERT') resp.on_alertStatusRequests = item.total
            if(item.status == 'ON_WATCH') resp.on_watchStatusRequests = item.total
            if(item.status == 'STARTED') resp.startedStatusRequests = item.total
            if (item.status == 'WAITING_REQUEST') resp.waiting_requestStatusRequests = item.total
            if (item.status == 'SUSPENDED') resp.suspendedStatusRequests = item.total
            if (item.status == 'IMPLANTED') resp.implantedStatusRequests = item.total
          })
          this.on_alertStatusRequests = resp.on_alertStatusRequests ?  resp.on_alertStatusRequests : 0
          this.on_watchStatusRequests = resp.on_watchStatusRequests ?  resp.on_watchStatusRequests : 0
          this.startedStatusRequests = resp.startedStatusRequests ?  resp.startedStatusRequests : 0
          this.waiting_requestStatusRequests = resp.waiting_requestStatusRequests ?  resp.waiting_requestStatusRequests : 0
          this.suspendedStatusRequests = resp.suspendedStatusRequests ?  resp.suspendedStatusRequests : 0
          this.implantedStatusRequests = resp.implantedStatusRequests ?  resp.implantedStatusRequests : 0
        }).catch(error => {
            this.is_loading = false;
            ErrorHandlerService.handle(error, this.$store);
        });
      },
      setStateValue(value){
        this.selectedState = value
        this.list()
      },
      setSegmentValue(value){
        this.selectedSegment = value
        this.list()
      },
      getClientsSegmentList() {
          const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
           }
          axios.get(`${CONSTANTS.API_V3_URL}/admin/clients/ramo_clientes`, config)
          .then(data => {
            data.data.forEach(element => {
              this.arraySelectSegment.push({
                text: element.ramo_descricao,
                value: element
              })
            })
          }).catch(error => {
            console.log(error)
          })
        },
        load_managers() {
          return this.user_svc3.list().then(response => {
                    this.managers = response.data
                    response.data.forEach(element => {
                      if (this.isResaleAdminLoggedIn || this.revenda_id == element.id_revenda){
                        this.arraySelectAttendant.push({
                            text: element.name,
                            value: element
                        })
                      }
                    })
                })
        },
        list() {
            this.is_loading = true;
            let params = {
                page: this.filters.page,
                status: this.filters.status ? this.filters.status.map(s => s.id) : [],
                name: this.filters.name,
                attendant_id: this.filters.attendant ? this.filters.attendant.id : null,
                resale: this.filters.resale ? this.filters.resale.id: null, 
                sort: 'tbl_cliente.cli_id',
                state: this.selectedState && this.selectedState.est_sigla,
                ramo: this.selectedSegment && this.selectedSegment.ramo_id
            }
            this.clients = []
            this.svc.list(params)
            .then(response => response.data).then(data => {
                data.data.forEach(element => {
                  if(element.attendance.status.id == "WAITING_REQUEST") element.attendance.status.name = "Aguardando cotação"
                  if(element.attendance.status.id == "prefinished") element.attendance.status = { id: 'prefinished ', name: 'Pre-Finalizado' }
                  if(element.attendance.status.id == "suspended_inactivity") element.attendance.status.name = "Suspenso por inatividade"
                  this.clients.push(element)
                });
                this.totalRequests = data.meta.total
                this.total_items = data.meta.total;
                this.is_loading = false;
                // this.clients.forEach(c => c.attendance = c.attendance || { status: {}, attendant: {} })
            }).catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    async created() {
        this.getResaleId()
        this.getListTotals()
        this.load_resales();
        this.load_region_data()
        this.getClientsSegmentList()
        this.load_managers()
        if (this.$route.query.id) {
          this.filters.resale = {id: this.$route.query.id}
          this.filters.status = this.statuses
        } else this.list();
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';
    
    .manage-status {
        display: flex;
        align-items: center;
        i.fa-edit {
            font-size: 1rem;
            color: $secondary-color;
            cursor: pointer;
            margin-right: 10px;
        }
    }
    .change-status {
        display: flex;
        align-items: center;
        width: 100%;
        .success-update {
            margin-left: 5px;
            visibility: hidden;
            color: $brand-success;
            &.visibile {
                visibility: 'visible';
            }
        }
    }

    .canceled {
        i {
            color: $brand-danger;
        }
    }
    .waiting {
        i {
            color: $yellow2;
        }
    }
    .confirmed {
        i {
            color: $ternary-color;
        }
    }

    .zap {
        i {
            color:#4AC959;
        }
        width: fit-content;
        &:hover {
            background: #4AC959;
            color: white;
            border-radius: 5px;
            i {
                color: white;
            }
        }
    }

    .actions {
        justify-content: center;
        i {
            margin-left: 10px;
            font-size: 1.2rem;
            cursor: pointer;
            &.fa-trash {
                color: red;
            }
        }
    }
    .radio-group {
        display: flex;
        align-items: center;
        .form-check {
            margin-right: 20px;
            margin-right: 20px;
            align-items: center;
            display: flex;
            height: 2rem;
            .form-check-label {
                cursor: pointer;
                margin-bottom: 0;
                display: flex;
                height: 100%;
                align-items: center;
                margin-left: 9px;
                font-size: 16px;
                color: black;
            }
            input {
                cursor: pointer;
                width: 1.5rem;
                height: 1.5rem;
                margin-right: 5px;
                margin-top: 0px;
                margin-bottom: 3px;
            }
        }
    }
    .filters {
        padding: 1rem;
        border-radius: 5px;
    }

.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-table-input-select{
  padding: 1vh 1vw;
  border-radius: 10px;
  color: #35495e;
  border: 1px solid #E5E5E5;
  &:focus{outline: none;}
  width: 90%;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: var(--primary-color);
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.attendances-page__clear-filter-container {
  display: flex;
  justify-content: flex-end;
}
.attendances-page__actions-container {
  display: flex;
  margin: 2em 0em;
  justify-content: space-between;
}
.attendances-page__product-input-container {
  display: flex;
  align-items: center;
}
.attendances-page__product-input {
  border: 1px solid #c2c2c3;
  font-size: 17px;
  padding: 11px;
  border-radius: 5px;
  width: 30vw;
}
.attendances-page__actions-inputs{
  width: 18%;
}
.attendances-page__actions-wrapper{
  width: 40%;
}
.attendances-page__product-input:focus {
  border: 1px solid #c2c2c3 !important;
}
.search-icon {
  position: relative;
  left: -35px;
  color:  #606060;
}
.select-input-width {

  margin-right: 10px;
}
.attendances-page__input-title-wrapper {
  padding-bottom: 5px;
}
.attendances-page__input-title {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  font-size: 20px;
}
.attendance-page-table-container {
  margin: 2em 0em;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}
.edit-user-icon{
  font-size: 1.7em;
  color: #606060;
  cursor: pointer;
}
.page-table-desc-column {
  padding: 0.5em 0.7em;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.actions-width {
  width: 8%;
}
.id-width {
  width: 9%;
}
.group-width {
  width: 13%;
}
.corporate-width {
  flex: 1;
}
.cnpj-width {
  width: 0%;
}
.phone-width {
  width: 0%;
}
.resale-width {
  width: 11%;
}
.area-width {
  width: 8%;
}
.state-width {
  width: 0%;
}
.responsible-width {
  width: 14%;
}
.status-width {
  width: 15%;
}
.answers-icon {
  font-size: 1.5em;
  color: #46AD5D;
  cursor: pointer;
  margin-right: 5px;
}
.monitor-icon {
  font-size: 1.5em;
  color: var(--primary-color);
  cursor: pointer;
  margin-right: 5px;
}
.invite-icon {
  font-size: 1.5em;
  color: var(--primary-color);
  cursor: pointer;
  margin-right: 5px;
}
.table-select-width {
  width: 15vw !important;
}
.edit-table-icon {
  margin-right: 1.5em;
  cursor: pointer;
}
.page-selected-filters-container {
  display: flex;
  margin: 1em 0em;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}

.page-table-header-mobile{
  display: none;
}
.attendances-page__infos-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.attendances-page__started-request {
  background-color: #F1F1F1;
  color: #404040;
  font-size: 1.2em;
  padding: 1vh 1vw;
  border-radius: 5px;
  font-weight: bold;
}
.attendances-page__objective-request {
  color: rgb(46, 46, 248);
  background-color: lightblue;
  font-size: 1.2em;
  padding: 1vh 1vw;
  font-weight: bold;
  border-radius: 5px;
}
.attendances-page__implanted-request {
  color: #0677C9;
  background-color: #17A2B81A;
  font-size: 1.2em;
  padding: 1vh 1vw;
  font-weight: bold;
  border-radius: 5px;
}
.attendances-page__waiting-first-request {
  color: var(--primary-color);
  background-color: var(--primary-color)1A;
  font-size: 1.2em;
  padding: 1vh 1vw;
  font-weight: bold;
  border-radius: 5px;
}
.attendances-page__on-watch-request {
  color: #187C3B;
  background-color: #2AD0621A;
  font-size: 1.2em;
  padding: 1vh 1vw;
  font-weight: bold;
  border-radius: 5px;
}
.attendances-page__on-alert-request {
  color: #404040;
  background-color: #FFDD20;
  font-size: 1.2em;
  padding: 1vh 1vw;
  font-weight: bold;
  border-radius: 5px;
}
.attendances-page__suspended-request {
  background-color: #E535351A;
  color: #CD2A2A;
  font-size: 1.2em;
  padding: 1vh 1vw;
  font-weight: bold;
  border-radius: 5px;
}
.attendances-page__total-request {
  color: rgb(197, 129, 2);
  background-color: lightgoldenrodyellow;
  font-size: 1.2em;
  padding: 1vh 1vw;
  font-weight: bold;
  border-radius: 5px;
}
.total-requests-container {
  display: flex;
  justify-content: center;
  color: #878787;
  font-size: 1.2em;
}
.attach-file-icon {
  font-size: 1.5em;
  color: #404040;
  cursor: pointer;
}

/deep/
.status-select .multiselect__tags {
  padding: 16px 25px 32px 10px !important;
}
@media only screen and (max-width: 1050px) {
  .page-container {
    font-size: 1.25vw;
  }
}
@media only screen and (max-width: 900px) {
  .status-width {
    width: 24%;
  }
}
@media only screen and (max-width: 800px) {
  .page-container {
    font-size: 1.5vw;
  }
}
@media only screen and (max-width: 630px){
  .desktop{display: none;}
  .attendances-page__clear-filter-container{
    margin-top: 2vh;
  }
  .page-container {
    font-size: 2.5vw;
    margin-right: 3vw;
  }
  .page-table-header{
    display: none;
  }
  .page-table-row{
    flex-direction: column;
    font-size: 1.2em;
  }
  .actions-width {
    width: 100%;
  }
  .id-width {
    width: 100%;
  }
  .group-width {
    width: 100%;
  }
  .corporate-width {
    flex: unset;
    width: 100%;
  }
  .cnpj-width {
    width: 100%;
  }
  .phone-width {
    width: 100%;
  }
  .resale-width {
    width: 100%;
  }
  .state-width {
    width: 100%;
  }
  .responsible-width {
    width: 100%;
  }
  .status-width {
    width: 100%;
  }
  .page-table-desc-column {
    display: flex;
    justify-content: space-between;
    text-align: end;
  }
  .page-table-input-select{
    width: fit-content;
  }
  .change-status{
    width: fit-content;
  }
  .page-table-header-mobile{
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
    display: flex;
  }
  .attendances-page__actions-container{
    flex-wrap: wrap;
  }
  .attendances-page__actions-inputs{
    width: 45%;
    margin-bottom: 2vh;
  }
  .attendances-page__actions-wrapper{
    width: 100%;
  }
  .attendances-page__infos-wrapper{
    flex-wrap: wrap;
  }
  .mobile-alert-size{
    width: 45%;
    margin-bottom: 1vh;
    text-align: center;
  }
}
@media only screen and (max-width: 500px) {
  .page-container {
    font-size: 3vw;
  }
}
@media only screen and (max-width: 380px) {
  .page-container {
    font-size: 3.5vw;
  }
}
</style>
